@import "styles/variables";

h3.contact__headline {
  margin-top: 3.1rem;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-top: 4.2rem;
  }
}

.contact {
  &__decoration > img {
    width: 125%;
  }
}
