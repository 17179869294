.underline-hover:is(:link, :visited) {
  position: relative;
}

.underline-hover:is(:link, :visited)::after {
  content: "";
  width: 100%;
  height: 1px;
  bottom: -0.5em;
  left: 0;
  position: absolute;
  background-color: currentColor;
  clip-path: inset(0 100% 0 0);
  opacity: 0.5;
  transition-property: opacity, clip-path;
  transition-duration: 0.2s;
}

.underline-hover.small:is(:link, :visited)::after {
  bottom: -0.3em;
}

@media (hover: hover) and (pointer: fine) {
  .underline-hover:is(:hover, :active)::after {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}
