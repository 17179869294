@import "styles/variables";

.slide-nav-item {
  &__button {
    display: block;
    width: max(12px, 1.2rem);
    height: auto;
    aspect-ratio: 1;
    border-radius: 99px;
    box-shadow: inset 0 0 0 1px $grey-medium;
    position: relative;
    overflow: hidden;

    @media (hover: hover) and (pointer: fine) {
      &:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.3s;
      border-radius: 99px;
      background-color: $grey-medium;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black-dark;
      transform: translateY(100%);
      border-radius: 99px;
      transition: transform 0.4s ease-in-out;
    }

    &--current {
      &::after {
        transform: translateY(0%);
      }
    }

    &:disabled {
      cursor: default;
    }
  }
}
