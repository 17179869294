@import "styles/variables";

.slider {
  max-width: 115.2rem;
  margin: 0 auto;
  padding: 0 $side-margin;

  margin-top: 5.7rem;
  margin-bottom: 7.2rem;
  position: relative;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-bottom: 6.8rem;
  }

  @media only screen and (max-width: 21.5em /* 344px */) {
    margin-top: 8rem;
    padding: 0;
  }

  &__background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 155.38%;
    transform: translate(-50%, -70%);

    @media only screen and (max-width: 43em /* 688px */) {
      display: none;
    }
  }

  &__background-image {
    width: 100%;
    display: block;
  }

  &__slides-container {
    position: relative;
  }

  &__slides {
    max-width: 81.2rem;
    height: 56.8rem;
    margin: 0 auto;
    position: relative;

    @media only screen and (max-width: 43em /* 688px */) {
      height: 68rem;
    }

    @media only screen and (max-width: 23.4375em /* 375px */) {
      height: 60rem;
    }
  }

  &__controls {
    position: absolute;
    width: 100%;
    bottom: 6rem;
    left: 0;

    @media only screen and (max-width: 49em /* 784px */) {
      max-width: 81.2rem;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      width: calc(100% - 3.2rem);
    }
  }

  &__nav {
    max-width: 26rem;
    margin: 0 auto;
    margin-top: 6.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 49em /* 784px */) {
      height: 9rem;
      margin-top: $gap;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      justify-content: center;
      gap: 4.5svw;
    }
  }
}

.controls {
  $trans: 0.5s;

  display: flex;
  justify-content: space-between;
  padding: 0 2.2rem;

  @media only screen and (max-width: 49em /* 784px */) {
    padding: 0;
  }

  &__button {
    width: 8rem;
    height: 12rem;
    backdrop-filter: blur(30px);
    border: 1px solid $grey-light;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    position: relative;
    overflow: hidden;

    transition: background-color $trans;

    @media only screen and (max-width: 49em /* 784px */) {
      transform: translateY(9rem + $gap);
      width: 7.2rem;
      height: 9rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black-dark;
        transition: transform $trans;
      }

      &--prev::before {
        transform: translateY(-100%);
      }

      &--next::before {
        transform: translateY(100%);
      }

      &:not(:disabled):hover {
        &::before {
          transform: translateY(0);
        }

        .controls__arrow {
          &--left:first-of-type {
            left: -50%;
            transform: translate(-50%, -50%) scaleY(0);
          }

          &--left:last-of-type {
            left: 50%;
            transform: translate(-50%, -50%) scaleY(1);
          }

          &--right:first-of-type {
            right: -50%;
            transform: translate(50%, -50%) scaleY(0);
          }

          &--right:last-of-type {
            right: 50%;
            transform: translate(50%, -50%) scaleY(1);
          }
        }
      }
    }

    &:disabled {
      border: 1px solid $grey-light;
      cursor: default;

      .controls__arrow {
        & * {
          fill: $grey-medium;
        }
      }
    }
  }

  &__arrow {
    width: 40%;
    position: absolute;
    top: 50%;
    transition: transform $trans, left $trans, right $trans;

    & * {
      fill: $black-dark;
    }

    &--left:first-of-type {
      left: 50%;
      transform: translate(-50%, -50%) scaleY(1);
    }

    &--left:last-of-type {
      left: 150%;
      transform: translate(-50%, -50%) scaleY(0);
      & * {
        fill: $white-almost;
      }
    }

    &--right:first-of-type {
      right: 50%;
      transform: translate(50%, -50%) scaleY(1);
    }

    &--right:last-of-type {
      right: 150%;
      transform: translate(50%, -50%) scaleY(0);
      & * {
        fill: $white-almost;
      }
    }
  }
}
