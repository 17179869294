@use "sass:math";
@import "styles/variables";

.team {
  margin-bottom: 10.5rem;
  overflow-x: clip;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-bottom: 4rem;
  }

  &__header {
    max-width: 162rem;
    margin: 0 auto;
    padding: 0 $side-margin;
    margin-top: 7.85rem;

    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
  }

  &__decoration {
    @media only screen and (max-width: 43em /* 688px */) {
      transform: translateY(-130%);
    }

    & > img {
      width: 125%;

      @media only screen and (max-width: 43em /* 688px */) {
        width: 105%;
      }
    }
  }

  &__headline-container {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: 100%;
    max-width: 56.8rem;
    aspect-ratio: 568 / 300;
    position: relative;
    margin-bottom: 15rem;

    @media only screen and (max-width: 53.1875em /* 851px */) {
      margin-top: 3.2rem;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      aspect-ratio: 568 / 280;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      backdrop-filter: blur(20px);
      z-index: -1;
    }
  }

  &__hills-container {
    position: absolute;
    width: math.div(982, 568) * 100%;
    left: math.div(276, 568) * -100%;
    top: math.div(98, 300) * 100%;
    z-index: -2;

    @media only screen and (max-width: 43em /* 688px */) {
      width: math.div(800, 568) * 100%;
      left: math.div(200, 568) * -100%;
      top: math.div(125, 300) * 100%;
    }
  }

  &__hills {
    width: 100%;
  }

  &__kenji {
    position: absolute;
    top: math.div(50.2, 300) * 100%;
    right: math.div(47, 568) * 100%;
    width: math.div(30, 568) * 100%;

    @media only screen and (max-width: 43em /* 688px */) {
      top: math.div(35, 300) * 100%;
      right: math.div(30, 568) * 100%;
      width: math.div(40, 568) * 100%;
    }
  }

  div.team__texture {
    opacity: 0.65;
  }

  &__headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }

  &__headline-number {
    font-family: $serif;
    font-style: italic;
    font-size: 8.6rem;
    letter-spacing: 0.05em;

    @media only screen and (max-width: 43em /* 688px */) {
      font-size: 7.145rem;
    }
  }

  &__headline-text {
    font-weight: 600;
    font-size: max(16px, 2.1rem);
  }
}
