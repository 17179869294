@use "sass:math";
@import "styles/variables";

.service-item {
  width: math.div(949, 1364) * 100%;
  aspect-ratio: 949 / 493;

  $gap: 6.77%;
  $gap-left: 10.12%;

  &:nth-of-type(1) {
    margin-left: $gap-left;
    top: 6rem;
    position: sticky;
    z-index: 1;

    @media only screen and (max-width: 53.1875em /* 851px */) {
      margin-left: 0;
    }

    .service-item__origami-container {
      width: math.div(120.46, 949) * 100%;
      top: math.div(75.1, 493) * 100%;
      right: math.div(68.5, 949) * 100%;
    }
  }

  &:nth-of-type(n + 2) {
    position: sticky;
    top: 6rem;
    transform: translateY(6rem);
    margin-left: $gap-left + $gap;
    z-index: 2;

    @media only screen and (max-width: 53.1875em /* 851px */) {
      margin-left: $gap-left;
    }

    .service-item__origami-container {
      width: math.div(57.69, 949) * 100%;
      top: math.div(61, 493) * 100%;
      right: math.div(82.3, 949) * 100%;
    }
  }

  &:nth-of-type(3) {
    top: 6rem;
    transform: translateY(12rem);
    margin-left: $gap-left + 2 * $gap;

    @media only screen and (max-width: 53.1875em /* 851px */) {
      margin-left: $gap-left * 2;
    }

    .service-item__origami-container {
      width: math.div(82.53, 949) * 100%;
      top: math.div(69, 493) * 100%;
      right: math.div(80.5, 949) * 100%;
    }
  }

  &:nth-of-type(4) {
    top: 6rem;
    transform: translateY(18rem);
    margin-left: $gap-left + 3 * $gap;

    .service-item__origami-container {
      width: math.div(166.25, 949) * 100%;
      top: math.div(68.6, 493) * 100%;
      right: math.div(51.9, 949) * 100%;
    }
  }

  &__container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(25px);

    position: relative;
  }

  &__background {
    display: block;
    position: absolute;
    width: math.div(95, 949) * 100%;
    top: math.div(50, 493) * 100%;
    left: 50%;

    @media only screen and (max-width: 43em /* 688px */) {
      left: 43%;
    }
  }

  &__background-image {
    display: block;
    width: 100%;
  }

  &__origami-container {
    position: absolute;
    display: block;
  }

  &__origami {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-property: opacity transform;
    transition-duration: 1.5s;
    transform: translate(-10%, 10%);

    @media only screen and (max-width: 43em /* 688px */) {
      transform: scale(1.1) translate(-10%, 10%);
    }
  }

  &__number {
    display: block;
    position: absolute;
    top: math.div(53.1, 493) * 100%;
    left: math.div(67, 949) * 100%;
    font-family: $serif;
    font-size: max(12px, 2.1rem);
    font-style: italic;
  }

  &__paragraph {
    font-weight: 600;
    font-size: 3.7rem;
    line-height: math.div(50, 37);
    width: math.div(674, 949) * 100%;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;

    @media only screen and (max-width: 77.375em /* 1238px */) {
      font-size: 3rem;
    }

    @media only screen and (max-width: 49em /* 784px */) {
      font-size: 2.6rem;
    }
  }

  &.animate {
    .service-item {
      &__paragraph {
        opacity: 1;
      }

      &__origami {
        opacity: 1;
        transform: translate(0, 0);

        @media only screen and (max-width: 43em /* 688px */) {
          transform: scale(1.1) translate(0, 0);
        }
      }
    }
  }

  @media only screen and (max-width: 43em /* 688px */) {
    width: 100%;
    aspect-ratio: auto;

    &__container {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 4.8rem;
      gap: 0;
    }

    &:nth-of-type(n) {
      margin-left: 0;
      left: 0;

      .service-item__origami-container {
        padding: 3.6rem 0;
        height: 18.6rem;
        width: auto;
        position: relative;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
      }
    }

    &__paragraph {
      padding-top: 0;
      font-size: 3rem;
      text-align: center;
      width: 100%;
      max-width: 50rem;
    }

    &__number {
      display: none;
    }
  }
}
