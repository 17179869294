@import "styles/variables";
@import "styles/utils/visually-hidden";

.nav-social-media {
  $pad-top: 1.85rem;

  @media only screen and (max-width: 43em /* 688px */) {
    align-self: center;
    width: 100%;
  }

  &__heading {
    font-family: $serif;
    font-size: max(12px, 1.6rem);
    font-style: italic;
    letter-spacing: 0.05em;
    margin-bottom: 2.8rem;

    @media only screen and (max-width: 43em /* 688px */) {
      @include visually-hidden();
    }
  }

  &__list {
    padding-left: 5.5rem;
    display: flex;
    gap: 5.5rem;

    font-size: max(16px, 1.6rem);
    font-weight: 600;
    letter-spacing: 0.05em;

    @media only screen and (max-width: 43em /* 688px */) {
      justify-content: left;
      padding-left: 3.75rem;
    }
  }

  &__item {
    @media only screen and (max-width: 43em /* 688px */) {
      flex: 0 1 10rem;
    }
  }

  &__link {
    text-align: center;
    display: block;
    padding-top: $pad-top;
    align-items: flex-start;
    position: relative;
    min-width: 5rem;
    height: 8.121rem;

    @media only screen and (max-width: 43em /* 688px */) {
      min-width: 0;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0;
      transform: scaleX(0);
      transform-origin: left bottom;

      background-color: $grey-medium;
      transition: transform 0.3s, opacity 0.3s;
    }

    &::before {
      top: -1px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &::before,
        &::after {
          opacity: 1;
          transform: scaleX(1);
        }

        .nav-social-media {
          &__arrow {
            opacity: 1;
            transform: translateX(-50%) rotate(-45deg) scaleY(1) rotate(45deg);
          }

          &__text {
            transform: translateY(180%);
          }
        }
      }
    }
  }

  &__text {
    display: block;
    transition: transform 0.3s;
  }

  &__arrow {
    transform: translateY(50%);
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: 0.2s;
    position: absolute;
    top: $pad-top;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg) scaleY(0) rotate(45deg);

    width: 1.671rem;
    height: auto;

    & * {
      fill: $black-dark;
    }
  }
}
