@use "sass:math";
@import "styles/variables";

.foundation-achievements {
  flex: 0 1 67.4rem;
  padding-bottom: 9rem;
  padding-top: 3.6rem;
  padding-left: 13.8rem;
  padding-right: 3.2rem;

  z-index: 1;

  background-color: $white-almost;

  @media only screen and (max-width: 70.875em /* 1134px */) {
    padding-left: 10rem;
    padding-bottom: 8rem;
    padding-top: 2.6rem;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    flex: 1 0 auto;
    width: 100%;
    margin-left: 4.8rem;
    padding: 4.8rem;
  }

  @media only screen and (max-width: 21.5em /* 344px */) {
    margin-left: 0;
    padding: $side-margin;
  }

  &__number {
    font-family: $serif;
    font-style: italic;
    font-size: 5rem;
    letter-spacing: 0.05em;
    margin-bottom: 1.6rem;
    margin-top: 5.5rem;

    display: block;

    span:not([aria-live]) {
      font-size: 0.6em;
      letter-spacing: 0em;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      font-size: 4rem;
    }
  }

  &__text {
    font-size: max(16px, 2.1rem);
    line-height: math.div(24, 21);
    max-width: 43.9rem;

    display: block;

    @media only screen and (max-width: 43em /* 688px */) {
      max-width: 43.9rem + $side-margin;
      padding-right: $side-margin;
    }

    &--small {
      margin-top: 5.2rem;
      font-size: max(14px, 1.6rem);
      line-height: math.div(24, 16);
      max-width: 41rem;

      @media only screen and (max-width: 43em /* 688px */) {
        max-width: 35rem;
      }
    }
  }
}
