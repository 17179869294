@import "styles/variables";

.logo {
  padding-left: 2.62rem;
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
  overflow: hidden;

  @media only screen and (max-width: 92.9375em /* 1487px */) {
    padding-left: 1.15rem;
  }

  @media only screen and (max-width: 81.875em /* 1310px */) {
    padding-left: 0;
  }

  @media only screen and (max-width: 50em /* 800px */) {
    flex: 1 1 auto;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    align-self: flex-start;
    z-index: 2;
  }

  &__link {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 1.85rem;

    @media only screen and (max-width: 43em /* 688px */) {
      width: 24rem;
      gap: 6%;
    }
  }

  &__circle {
    z-index: 1;
  }

  &__text {
    clip-path: inset(0 0 0 0);
    transition: transform 0.6s, clip-path 0.5s, opacity 0.6s;
    transition-timing-function: ease-out;

    @media only screen and (max-width: 43em /* 688px */) {
      & > * {
        fill: $white-almost;
        transition: fill 0.2s;
      }

      &--force-dark > * {
        fill: $black-dark;
      }
    }
  }
}

.logo.combined {
  .logo {
    &__text {
      transform: translateX(-150%);
      transform-origin: left;
      clip-path: inset(0 0 0 100%);
      opacity: 0;
      transition: transform 0.6s, clip-path 0.5s, opacity 0.6s;
      transition-timing-function: ease-in-out;
    }
  }
}
