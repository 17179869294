@use "sass:math";
@import "styles/variables";
@import "styles/mixins";

.foundation {
  margin-bottom: 9.5rem;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-bottom: -2rem;
  }

  &__headline.section-headline {
    margin-top: 4.9rem;
    margin-bottom: 8.25rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 9rem;
      margin-bottom: 4rem;
    }
  }

  &__decoration {
    @media only screen and (max-width: 43em /* 688px */) {
      &.heading-decoration {
        transform: translateY(-147%);
      }
    }

    & > img {
      width: 100%;
    }
  }
}

.foundation-about {
  max-width: 136.4rem + 2 * $side-margin;
  margin: 0 auto;
  margin-bottom: 12.4rem;
  padding: 0 $side-margin;

  display: flex;
  justify-content: flex-end;
  gap: 4.3rem;

  @media only screen and (max-width: 49em /* 784px */) {
    padding: 0;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    flex-direction: column;
    gap: 0;
  }

  &__container {
    &--text {
      flex: 0 1 50.9rem;
      font-size: 2.1rem;
      line-height: math.div(32, 21);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.2rem;

      @media only screen and (max-width: 49em /* 784px */) {
        padding-left: $side-margin;
      }

      @media only screen and (max-width: 43em /* 688px */) {
        flex: 1 1 auto;
        gap: 2.8rem;
        max-width: 40rem;
        align-self: center;
        margin-bottom: -0.8rem;
      }
    }

    &--logo {
      flex: 0 1 67.4rem;
      aspect-ratio: 674 / 270;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $side-margin;

      @media only screen and (max-width: 49em /* 784px */) {
        flex: 0 0 40.4rem;

        .foundation-about__logo {
          width: 80%;
        }
      }

      @media only screen and (max-width: 43em /* 688px */) {
        aspect-ratio: auto;
        flex: 1 1 auto;
        max-width: 49rem;
        align-self: center;

        display: flex;
        justify-content: center;
        gap: $side-margin;
        margin-bottom: -8rem;
      }
    }
  }

  &__texture.texture {
    opacity: 0.6;

    @media only screen and (max-width: 43em /* 688px */) {
      display: none;
    }
  }

  &__badge-container {
    position: absolute;
    top: 0;
    right: getRelativeDimension(55, 674);
    width: max(10rem, getRelativeDimension(150, 674));
    transform: translateY(-50%);

    @media only screen and (max-width: 43em /* 688px */) {
      flex: 1 1 auto;
      position: relative;
      transform: none;
      right: 0;
      order: 1;
    }
  }

  &__badge {
    width: 100%;
  }

  &__logo {
    max-width: 30rem;

    @media only screen and (max-width: 43em /* 688px */) {
      max-width: 27rem;
    }
  }
}
