@import "styles/variables";

.reviews-container {
  overflow-x: clip;
}

.reviews {
  h3.reviews__headline {
    margin-top: 4.9rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-bottom: -4.8rem;
    }
  }

  @media only screen and (max-width: 43em /* 688px */) {
    &__decoration > img {
      width: 95%;
    }
  }
}
