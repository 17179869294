@import "styles/variables";

.heading-decoration {
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  justify-self: start;
  align-self: start;

  width: 8rem;
  height: auto;
  aspect-ratio: 80 / 189;

  $border-style: 1px solid $grey-medium;
  border-top: $border-style;
  border-bottom: $border-style;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 53.1875em /* 851px */) {
    transform: translateY(-100%);
  }

  @media only screen and (max-width: 43em /* 688px */) {
    width: 7.145rem;
    aspect-ratio: 1 / 2;
    transform: translateY(-110%);
  }

  @media only screen and (max-width: 21.5em /* 344px */) {
    display: none;
  }

  &__image {
    width: 100%;
    height: auto;
  }
}
