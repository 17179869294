@use "sass:math";
@import "styles/variables";

.credibility {
  h3.credibility__primary-headline {
    margin-bottom: -3.2rem;

    @media only screen and (max-width: 53.1875em /* 851px */) {
      margin-top: 5rem;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 9rem;
      margin-bottom: 0.8rem;
    }
  }

  &__headline {
    max-width: 108.6rem;
    margin: 0 auto;
    margin-top: 4.42rem;
    margin-bottom: 4.3rem;
    padding: 0 $side-margin;

    font-family: $serif;
    font-size: 3.7rem;
    line-height: math.div(50, 37);
    text-align: center;

    @media only screen and (max-width: 49em /* 784px */) {
      font-size: 3rem;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      font-size: 4rem;
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
    }
  }

  &__decoration {
    @media only screen and (max-width: 43em /* 688px */) {
      &.heading-decoration {
        transform: translateY(-141%);
      }
    }

    & > img {
      width: 83.75%;
    }
  }

  &__logos {
    margin-right: 3.9rem;
  }

  &__logo-container {
    aspect-ratio: 1;
    width: 18rem;
    padding: 2.5rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
  }

  &__logo {
    width: 100%;
  }

  &__texts {
    max-width: 108.6rem + 2 * $side-margin;
    margin: 0 auto;
    margin-bottom: 5.98rem;
    padding: 0 $side-margin;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $gap;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 3.6rem;
      margin-bottom: 4rem;
    }
  }

  &__paragraph {
    font-size: 2.1rem;
    line-height: math.div(28, 16);

    &--strong {
      flex: 0 1 50rem;
      font-size: max(16px, 2.1rem);
      font-weight: 600;
      line-height: math.div(28, 21);
      margin-bottom: 3.5rem;

      @media only screen and (max-width: 43em /* 688px */) {
        grid-column: span 2;
        text-align: center;
        max-width: 36rem;
        justify-self: center;
        margin: 0 auto;
        margin-bottom: 0;
        margin-top: 4rem;
      }
    }

    &--weak {
      flex: 0 1 53.6rem;
      padding-left: $gap;

      @media only screen and (max-width: 43em /* 688px */) {
        order: 2;
        grid-column: span 2;
        justify-self: center;
        max-width: 39rem;
      }
    }
  }

  &__origami {
    display: block;
    margin: 0 auto;
    margin-top: 7.2rem;

    @media only screen and (max-width: 43em /* 688px */) {
      width: 6rem;
      margin-top: 4rem;
    }
  }

  &__gallery {
    max-width: 142.8rem;
    margin: 0 auto;
    margin-top: 9.55rem;
    margin-bottom: 12.85rem;
    padding: 0 $side-margin;

    display: flex;
    gap: $gap;

    @media only screen and (max-width: 49em /* 784px */) {
      padding: 0;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 4.5rem;
      margin-bottom: 4rem;
      flex-direction: column;
    }
  }

  &__image-container {
    flex: 1 1 0;

    &--1 {
      transform: translateY(-4.25rem);
    }

    &--2 {
      transform: translateY(4.25rem);
    }

    @media only screen and (max-width: 43em /* 688px */) {
      &--1,
      &--2 {
        transform: none;
      }

      &--1 {
        margin-right: 4.8rem;
      }

      &--2 {
        margin-left: 4.8rem;
      }
    }
  }

  &__image {
    width: 100%;
    display: block;
  }
}
