@import "styles/variables";

.credibility-awards {
  display: flex;
  grid-column: span 2;
  margin: 0 auto;
  margin-top: 10.6rem;
  margin-bottom: 8rem;
  padding: 0 $side-margin;

  max-width: 140rem + 2 * $side-margin;

  @media only screen and (max-width: 43em /* 688px */) {
    display: grid;

    position: relative;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(8, minmax(0, 1fr));
    order: 1;
    margin-top: 3.6rem;
    margin-bottom: 2.5rem;
    max-width: 50rem + 2 * $side-margin;
  }

  &__image-container {
    padding: 0 2.85rem;
    $shadowVar: 2.2rem;
    box-shadow: calc($shadowVar + 1px) 0 0 -1 * $shadowVar $grey-medium;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      box-shadow: none;
      padding-right: 0;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      $padding: 4%;
      $narrow: -5%;
      &:nth-of-type(n) {
        align-self: center;
        box-shadow: none;
        padding: $padding;
      }

      &:nth-of-type(8) {
        grid-row: 1 / span 1;
        grid-column: 1 / span 2;
        margin-bottom: $narrow;
      }

      &:nth-of-type(7) {
        grid-row: 1 / span 1;
        grid-column: 3 / span 2;
        margin-bottom: $narrow;
      }

      &:nth-of-type(6) {
        grid-row: 1 / span 1;
        grid-column: 5 / span 2;
        margin-bottom: $narrow;
      }

      &:nth-of-type(5) {
        grid-row: 1 / span 1;
        grid-column: 7 / span 2;
        margin-bottom: $narrow;
      }

      &:nth-of-type(4) {
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
        margin-top: $narrow;
      }

      &:nth-of-type(3) {
        grid-row: 2 / span 1;
        grid-column: 3 / span 2;
        margin-top: $narrow;
      }

      &:nth-of-type(2) {
        grid-row: 2 / span 1;
        grid-column: 5 / span 2;

        transform: scale(1.06);
        margin-top: $narrow;
        padding: 0;
        padding-top: $padding;
      }

      &:nth-of-type(1) {
        grid-row: 2 / span 1;
        grid-column: 7 / span 2;
        margin-top: $narrow;
      }

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        transform: translateX(15%);
      }

      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7),
      &:nth-of-type(8) {
        transform: translateX(-15%);
      }
    }
  }

  &__image {
    width: 100%;
  }
}
