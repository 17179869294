@use "sass:math";
@import "styles/variables";

.benefit {
  flex: 1 1 calc(50% - 0.8rem);
  aspect-ratio: 536 / 341;
  position: relative;
  padding: 4.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(25px);
  // visibility: hidden;

  @media only screen and (max-width: 63.875em /* 1022px */) {
    gap: 3.2rem;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    width: calc(100% - 4.8rem);
    aspect-ratio: 536 / 315;
  }

  @media only screen and (max-width: 21.5em /* 344px */) {
    width: 100%;
  }

  &__kenji {
    position: absolute;
    width: math.div(30, 536) * 100%;
    top: math.div(40, 341) * 100%;
    right: math.div(50, 536) * 100%;
  }

  &__heading {
    font-family: $serif;
    font-style: italic;
    font-size: 5rem;
    margin-top: 1.5rem;

    @media only screen and (max-width: 63.875em /* 1022px */) {
      font-size: 3.7rem;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 3rem;
    }
  }

  &__small {
    font-size: 5rem;

    @media only screen and (max-width: 63.875em /* 1022px */) {
      font-size: 3.7rem;
    }
  }

  &:nth-of-type(2) {
    margin-top: 10.8rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 0;
      align-self: flex-end;
    }
  }

  &:nth-of-type(n) {
    .benefit__heading {
      font-size: 8.6rem;
      margin-top: 0;

      @media only screen and (max-width: 63.875em /* 1022px */) {
        font-size: 6.8rem;
      }

      @media only screen and (max-width: 43em /* 688px */) {
        margin-top: 1rem;
      }
    }
  }

  &:nth-of-type(4) {
    margin-top: 12rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 0;
      align-self: flex-end;
    }
  }

  @media only screen and (max-width: 43em /* 688px */) {
    &:nth-of-type(odd) {
      padding-left: 8.5rem;

      .benefit__kenji {
        right: 3rem;
      }
    }

    &:nth-of-type(even) {
      padding-left: 8.5rem - 4.8rem;

      .benefit__kenji {
        right: 3rem + 4.8rem;
      }
    }
  }

  @media only screen and (max-width: 21.5em /* 344px */) {
    &:nth-of-type(n) {
      padding-left: 3.2rem;

      .benefit__kenji {
        display: none;
      }

      .benefit__heading {
        margin-top: 0;
      }
    }
  }

  &__paragraph {
    font-size: max(14px, 2.1rem);
    line-height: math.div(28, 21);
    max-width: 38rem;
  }
}
