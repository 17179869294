@use "sass:math";
@import "styles/variables";

.why-us {
  margin-bottom: 7.5rem;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-bottom: 4.2rem;
  }

  &__container {
    max-width: 162rem;
    margin: 0 auto;
    margin-top: 10.5rem;
    padding: 0 $side-margin;
    position: relative;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @media only screen and (max-width: 77.25em /* 1236px */) {
      grid-template-columns: min-content 1fr;
      gap: $side-margin;
    }

    @media only screen and (max-width: 53.1875em /* 851px */) {
      grid-template-columns: 1fr;
    }

    @media only screen and (max-width: 49em /* 784px */) {
      padding: 0;
    }
  }

  &__decoration {
    @media only screen and (max-width: 53.1875em /* 851px */) {
      transform: translateY(calc(-100% - $side-margin));
    }

    @media only screen and (max-width: 49em /* 784px */) {
      margin-left: $side-margin;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      transform: translateY(-150%);
    }

    & > img {
      width: 125%;
    }
  }

  &__benefits {
    max-width: 108.8rem;
    width: 100%;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: $gap;
    row-gap: math.div($gap, 2);
    position: relative;

    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: center;

    @media only screen and (max-width: 77.25em /* 1236px */) {
      grid-column: 2 / 3;
    }

    @media only screen and (max-width: 53.1875em /* 851px */) {
      grid-column: 1 / 2;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 4.8rem;
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      gap: 1.6rem;
    }
  }

  &__background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-53%, -58%);
    width: math.div(509, 1088) * 100%;

    &:nth-of-type(n + 2) {
      display: none;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      width: math.div(950, 1088) * 100%;
      transform: translate(-53%, -60%) rotate(10deg);

      &:nth-of-type(n + 2) {
        display: block;
      }

      &:nth-of-type(2) {
        transform: translate(-53%, -122%) rotate(10deg);
      }

      &:nth-of-type(3) {
        transform: translate(-53%, 8%) rotate(10deg);
      }
    }
  }
}
