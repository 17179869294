@import "styles/variables";
@import "styles/utils/visually-hidden";

.main-navigation {
  margin-bottom: 3rem;
  $transition: 0.3s;

  &__heading {
    font-family: $serif;
    font-style: italic;
    font-size: max(12px, 1.6rem);
    letter-spacing: 0.05em;
    margin-bottom: 1.9rem;

    @media only screen and (max-width: 43em /* 688px */) {
      @include visually-hidden();
    }
  }

  &__list {
    font-size: 2.8rem;
    font-weight: 600;

    display: flex;

    flex-direction: column;

    @media only screen and (max-width: 107.5em /* 1720px */) {
      font-size: max(21px, 2.4rem);
    }

    @media only screen and (max-width: 43em /* 688px */) {
      gap: 1.6rem;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 5.2rem;
    position: relative;

    @media (hover: hover) and (pointer: fine) {
      &:not(&--current):hover {
        .main-navigation {
          &__section-arrow {
            &::before {
              transform: scaleX(1);
            }
          }

          &__arrow {
            opacity: 1;
            transform: translateY(-50%) scaleY(1);
          }

          &__section-name {
            transform: translateX(3.95rem);
          }
        }
      }
    }
  }

  &__section-arrow {
    flex: 0 0 28rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    padding: 1.4rem 0;

    @media only screen and (max-width: 107.5em /* 1720px */) {
      padding: 1rem 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% - 1px);
      width: 100%;
      border-top: 1px solid $grey-medium;
      border-bottom: 1px solid $grey-medium;
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: transform $transition;
    }
  }

  &__section-name {
    display: block;
    transition: transform $transition;
  }

  &__bullet {
    display: block;
    flex: 0 0 max(12px, 1.2rem);
    aspect-ratio: 1;
    background-color: $red;
    border-radius: 99px;
    transform: scale(0);
    transition: transform 0.2s;
  }

  &__arrow {
    height: 38%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) scaleY(0);

    opacity: 0;
    transition: opacity $transition, transform $transition;
  }

  &__link--current {
    .main-navigation {
      &__bullet {
        transform: scale(1);
      }
    }
  }
}
