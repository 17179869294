@import "styles/variables";

.contact-people {
  position: relative;
  max-width: 108.8rem;
  margin: 0 auto;

  @media only screen and (max-width: 92.6875em /* 1483px */) {
    margin-top: 3.2rem;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    margin-top: 2.4rem;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: $gap;

    @media only screen and (max-width: 72em /* 1152px */) {
      gap: $side-margin;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      flex-direction: column;
      gap: 4.8rem;
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      gap: 1.6rem;
    }
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -2;
  }

  &__background-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -58%);
    width: 120%;
    z-index: -1;

    @media only screen and (max-width: 43em /* 688px */) {
      transform: translate(-45%, -50%) rotate(-38deg) scaleX(-1);
      width: 170%;
    }
  }

  &__background-image {
    width: 100%;
  }
}
