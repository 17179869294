@use "sass:math";
@import "styles/variables";

.what-we-do {
  margin-bottom: 12.5rem;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-bottom: 27rem;
  }

  &__decoration {
    position: sticky;
    top: 6rem;

    @media only screen and (max-width: 53.1875em /* 851px */) {
      top: 0;
      position: relative;
      transform: translateY(calc(-100% - $side-margin));
    }

    @media only screen and (max-width: 43em /* 688px */) {
      transform: translate($side-margin, -142%);
    }
    & > img {
      width: math.div(64, 80) * 100%;
    }
  }

  &__container {
    max-width: 162rem;
    margin: 0 auto;
    margin-top: 10rem;
    padding: 0 $side-margin;
    padding-bottom: 18rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 9rem;
      padding: 0;
    }
  }

  &__list-container {
    width: 100%;
    max-width: 136.4rem;
    justify-self: center;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &__list {
    position: relative;
    display: grid;
  }
}
