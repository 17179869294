@use "sass:color";
@use "sass:math";
@import "styles/variables";

.footer {
  margin-top: 31.5rem;
  position: relative;

  &__top-link {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, 0) scaleX(1);

    @media (hover: hover) and (pointer: fine) {
      opacity: 0;
      transform: translate(-50%, 0) scaleX(0);
      transition: transform 0.3s, opacity 0.3s;
    }
  }

  &__top-link-image {
    width: 100%;
  }

  &__origami-container {
    position: relative;
    display: block;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .footer {
          &__origami {
            transform: translate(-5%, -5.2rem);
          }

          &__top-link {
            transform: translate(-50%, 0) scaleX(1);
            opacity: 1;
          }
        }
      }
    }
  }

  &__origami {
    transform: translate(-5%, -5.2rem);

    @media (hover: hover) and (pointer: fine) {
      transform: translate(-7%, 0);
      transition: transform 0.3s;
    }
  }

  @media only screen and (max-width: 43em /* 688px */) {
    margin-top: max(22rem, 40svw);
  }

  @media only screen and (max-width: 30em /* 480px */) {
    &__origami-container {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 8.6rem;
    }

    &__origami {
      width: 90%;
    }

    &__top-link {
      display: block;
    }
  }

  &__container {
    max-width: 170.4rem;
    padding: 0 $side-margin;
    padding-bottom: clamp($side-margin, 10svw, 8.7rem);
    padding-top: 9.3rem;
    margin: 0 auto;
    display: flex;
    color: $grey-light;

    @media only screen and (max-width: 49em /* 784px */) {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: 30em /* 480px */) {
      flex-direction: column;
      align-items: center;
      padding-top: 33rem;
    }
  }

  &__flex-container {
    flex: 0 0 33.33%;
    display: flex;

    @media only screen and (max-width: 49em /* 784px */) {
      flex: 0 0 50%;
    }

    @media only screen and (max-width: 30em /* 480px */) {
      flex: 0 1;
    }

    &--middle {
      flex: 0 1 33.33%;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 7.16rem;

      @media only screen and (max-width: 49em /* 784px */) {
        margin-top: 6rem;
        flex: 0 0 100%;
        order: 1;
      }
    }

    &--right {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      gap: 9.23rem;

      @media only screen and (max-width: 30em /* 480px */) {
        align-items: center;
      }

      @media only screen and (max-width: 30em /* 480px */) {
        gap: 7rem;
      }
    }
  }

  &__logo-container {
    max-width: 20.1rem;
    position: relative;
  }

  &__logo {
    width: 100%;
    height: auto;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $violet;
    z-index: -3;
  }

  &__sun-blur-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    backdrop-filter: blur(30px);
  }

  &__sun-container {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 50%;
    transform: translate(-50%, math.div(158, 590) * -100%);
  }

  &__sun {
    width: clamp(45rem, 80svw, 59rem);
    height: auto;
    aspect-ratio: 1;
    border-radius: 9999px;

    background: linear-gradient(
      to top,
      transparent,
      color.adjust($red-pink, $alpha: -0.57) 57.2%,
      $red
    );

    @media only screen and (max-width: 43em /* 688px */) {
      width: min(82svw, 59rem);
    }
  }

  &__copyright {
    font-size: max(12px, 1.2rem);
    line-height: math.div(18, 12);
    text-align: center;

    @media only screen and (max-width: 30em /* 480px */) {
      max-width: 25rem;
      margin-top: -3rem;
    }
  }

  &__privacy-link:is(:link, :visited) {
    padding-bottom: 0.3rem;
    border-bottom: 1px solid currentColor;

    @media (hover: hover) and (pointer: fine) {
      border-bottom: 1px solid transparent;
    }
  }

  &__privacy-link:is(:hover, :active) {
    border-bottom: 1px solid currentColor;
  }
}
