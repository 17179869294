@use "sass:math";
@import "styles/variables";

.footer-social-media {
  font-size: max(14px, 1.6rem);
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  max-width: 38rem;

  $pad-top: 1.85rem;

  @media only screen and (max-width: 30em /* 480px */) {
    min-width: 31.45rem;
    margin-top: 6rem;
  }

  &__heading {
    font-family: $serif;
    font-style: italic;
    text-transform: none;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 3.66rem;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__link {
    text-align: center;
    display: block;
    padding-top: $pad-top;
    align-items: flex-start;
    position: relative;
    min-width: 0;
    height: 8.121rem;

    @media (hover: hover) and (pointer: fine) {
      min-width: 5rem;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0;
      transform: scaleX(0);
      transform-origin: left bottom;

      background-color: $grey-medium;
      transition: transform 0.3s, opacity 0.3s;
    }

    &::before {
      top: -1px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &::before,
        &::after {
          opacity: 1;
          transform: scaleX(1);
        }

        .footer-social-media {
          &__arrow {
            opacity: 1;
            transform: translateX(-50%) rotate(-45deg) scaleY(1) rotate(45deg);
          }

          &__text {
            transform: translateY(180%);
          }
        }
      }
    }
  }

  &__text {
    display: block;
    transition: transform 0.3s;
  }

  &__arrow {
    transform: translateY(50%);
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: 0.2s;
    position: absolute;
    top: $pad-top;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg) scaleY(0) rotate(45deg);

    width: 1.671rem;
    height: auto;

    & * {
      fill: $grey-light;
    }
  }
}
