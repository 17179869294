* {
  background-color: transparent;
  color: inherit;

  box-sizing: inherit;

  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  line-height: inherit;
  text-decoration: inherit;

  list-style: none;

  border: none;
  outline: none;

  margin: 0;
  padding: 0;

  -webkit-tap-highlight-color: transparent; // removes touch highlight
}

a:is(:hover, :active, :focus, :focus-visible, :link) {
  text-decoration: none;
}

button {
  cursor: pointer;
}

strong {
  font-weight: 600;
}
