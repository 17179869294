@use "sass:color";
@use "sass:math";
@import "styles/variables";

.hero-container {
  width: 100%;
  margin-bottom: 7rem;

  $grid-template: minmax(12rem, 14.28rem);
  display: grid;
  grid-template-columns:
    [start] 1fr [content-start] repeat(8, $grid-template)
    [divider] repeat(2, $grid-template)
    [content-end] 1fr [end];
  grid-template-rows: clamp(70rem, 100dvh, 108rem);

  overflow-x: clip;

  @media only screen and (max-width: 50em /* 800px */) {
    grid-template-rows: clamp(80rem, 100svh, 108rem);
  }

  @media only screen and (max-width: 43em /* 688px */) {
    grid-template-columns: 1fr;
    grid-template-rows:
      math.div(720 - 432, 720) * 100%
      math.div(432, 720) * 100%;
    height: math.div(720, 390) * 100dvw;

    margin-bottom: 0;
  }

  @media only screen and (max-width: 43em /* 688px */) and (max-aspect-ratio: 386 / 728) {
    min-height: 100svh;
  }

  @media only screen and (max-width: (math.div(400, 16) * 1em) /* 400px */) {
    margin-top: 2rem;
    box-shadow: 0 -2rem 0 0 #3b243b;
  }
  @media only screen and (max-width: (math.div(350, 16) * 1em)) {
    margin-top: 4rem;
    box-shadow: 0 -4rem 0 0 #3b243b;
  }
  @media only screen and (max-width: (math.div(215, 16) * 1em)) {
    margin-top: 6rem;
    box-shadow: 0 -6rem 0 0 #3b243b;
  }

  &__brown {
    grid-column: divider / end;
    grid-row: 1 / 2;
    position: relative;
    overflow: hidden;

    background: #3b243b;

    @media only screen and (max-width: 43em /* 688px */) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }

  #circles-scene {
    width: 100%;
    height: 100%;
  }

  &__circle-container {
    width: 100%;
    height: 100%;
  }

  &__circle {
    position: absolute;
    background: linear-gradient(to top, $red-pink, $red);
    aspect-ratio: 1;
    border-radius: 999px;
    transform: rotate(69deg) translateY(10%);
    opacity: 0.2;

    &--1 {
      height: math.div(340, 1080) * 100%;
      left: math.div(175.7, 554) * 100%;
      top: math.div(-146.9, 1080) * 100%;
      filter: blur(10px);
      animation: blush 2s infinite 1s alternate ease-in-out;
    }

    &--2 {
      height: math.div(282, 1080) * 100%;
      left: math.div(397.8, 554) * 100%;
      top: math.div(559, 1080) * 100%;
      filter: blur(6px);
      animation: blush 2s infinite 2s alternate ease-in-out;
    }

    &--3 {
      height: math.div(483, 1080) * 100%;
      left: math.div(272.1, 554) * 100%;
      top: math.div(638.7, 1080) * 100%;
      filter: blur(15px);
      animation: blush 4s infinite alternate ease-in-out;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      display: none;
    }

    @keyframes blush {
      from {
        filter: blur(5px);
        transform: rotate(69deg) translateY(10%);
        transition-timing-function: ease-in-out;
      }
      to {
        filter: blur(15px);
        transform: rotate(0) translateY(0);
        transition-timing-function: ease-in-out;
      }
    }
  }

  &__sun {
    grid-column: start / divider;
    grid-row: 1 / 2;
    justify-self: end;
    pointer-events: none;
    position: relative;

    aspect-ratio: 1;
    width: auto;
    height: min(math.div(650, 1080) * 100%, 65rem);

    transform: translate(50%, math.div(190.8, 650) * 100%);

    @media only screen and (max-width: 43em /* 688px */) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      transform: translateY(-50%);
      justify-self: center;
      align-self: start;
      z-index: 0;
      height: auto;
      width: math.div(320, 390) * 100%;
    }
  }

  &__sun-image {
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;

    aspect-ratio: 1;
    width: 100%;

    border-radius: 999px;
    background: linear-gradient(to bottom, $red, $red-pink);
    box-shadow: 0 0 8rem color.adjust(#e61c64, $alpha: -0.5);
  }

  &__grey {
    grid-column: start / divider;
    grid-row: 1 / 2;
    backdrop-filter: blur(40px);
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 43em /* 688px */) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }

  &__grey-gradient {
    grid-column: start / divider;
    grid-row: 1 / 2;
    overflow: hidden;

    background: linear-gradient(to bottom, transparent 50%, $white-dark);

    z-index: 1;

    @media only screen and (max-width: 43em /* 688px */) {
      display: none;
    }
  }

  &__stone {
    grid-column: start / divider;
    grid-row: 1 / 2;
    z-index: 1;
    aspect-ratio: 1168 / 1547;
    justify-self: end;
    pointer-events: none;
    position: relative;

    animation: fade-in 0.8s 0.2s ease-in-out both;

    max-height: min(math.div(773, 1080) * 100%, 77.3rem);
    transform: translate(math.div(150, 584) * 100%, math.div(121, 773) * 100%);

    @media only screen and (max-width: 43em /* 688px */) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      max-height: none;
      width: math.div(303, 390) * 100%;
      justify-self: center;
      align-self: start;
      transform: translate(
        math.div(-20, 303) * 100%,
        math.div(-186.4, 401) * 100%
      );
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #stone-scene {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__stone-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    @media only screen and (max-width: 43em /* 688px */) {
      height: auto;
      width: 100%;
    }
  }

  &__geisha {
    grid-column: start / divider;
    grid-row: 1 / 2;
    aspect-ratio: 920 / 2543;
    justify-self: end;
    pointer-events: none;

    min-height: min(math.div(1228, 1080) * 100%, 122.8rem);
    max-height: min(math.div(1228, 1080) * 100%, 122.8rem);

    transform: translate(math.div(171, 444) * 100%, math.div(20, 1228) * 100%);
    z-index: 1;

    @media only screen and (max-width: 43em /* 688px */) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      min-height: auto;
      max-height: none;
      width: math.div(215, 390) * 100%;
      justify-self: center;
      align-self: start;
      transform: translate(
        math.div(23.8, 215) * 100%,
        math.div(-224.5, 595) * 100%
      );
    }
  }

  #geisha-scene {
    width: 100%;
    height: 100%;
  }

  &__geisha-image {
    display: block;
    height: 100%;

    @media only screen and (max-width: 43em /* 688px */) {
      height: auto;
      width: 100%;
    }
  }

  &__strength {
    grid-column: start / divider;
    grid-row: 1 / 2;
    aspect-ratio: 180 / 579;
    justify-self: end;
    pointer-events: none;

    max-height: min(math.div(193, 1080) * 100%, 19.2rem);

    transform: translate(math.div(237, 60) * 100%, math.div(465, 192) * 100%);
    z-index: 1;

    @media only screen and (max-width: 43em /* 688px */) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      align-self: start;
      justify-self: center;
      max-height: none;
      width: math.div(33, 390) * 100%;
      transform: translate(
        math.div(153.85, 33) * 100%,
        math.div(202.8, 105) * 100%
      );
    }
  }

  #strength-scene {
    width: 100%;
    height: 100%;
  }

  &__strength-image {
    display: block;
    width: 100%;

    @media only screen and (max-width: 43em /* 688px */) {
      height: auto;
      width: 100%;
    }
  }
}
