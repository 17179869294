@import "styles/variables";

.language-item {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media only screen and (max-width: 43em /* 688px */) {
    gap: 3.2rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:not(.language-item--current):hover {
      .language-item__bullet::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &__bullet {
    display: block;
    flex: 0 0 max(12px, 1.2rem);
    aspect-ratio: 1;
    background-color: none;
    box-shadow: inset 0 0 0 1px $grey-medium;
    border-radius: 99px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-radius: 99px;
      background-color: $grey-medium;
      transition: transform 0.2s;
      transform: translate(-50%, -50%) scale(0);
    }
  }

  &--current {
    .language-item {
      &__bullet {
        background-color: $red;
        box-shadow: none;
      }
    }
  }
}
