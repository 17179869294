@use "sass:math";
@import "styles/variables";

.section-headline {
  max-width: 162rem;
  margin: 0 auto;
  padding: 0 $side-margin;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  text-align: center;

  font-family: $serif;
  font-size: 3.7rem;
  line-height: math.div(50, 37);

  & > span {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  @media only screen and (max-width: 49em /* 784px */) {
    font-size: 3.6rem;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    font-size: 4rem;
  }
}
