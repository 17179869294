.texture {
  display: block;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
  background: repeat url("../../images-react/backgrounds/texture.jpg");
}
