@use "sass:math";
@import "styles/variables";

.credibility {
  &__stats-container {
    max-width: 108.6rem + 2 * $side-margin;
    margin: 0 auto;
    padding: 0 $side-margin;
  }

  &__stats-image {
    width: 107.5%;
    transform: translateX(-6.8%);

    rect {
      clip-path: inset(100% 0 0 0);
    }

    text {
      font-size: 11px;
      text-align: center;
    }

    text:first-of-type {
      font-size: 14px;
      font-weight: 600;
    }

    text[id^="_"] {
      font-size: 13px;
      font-weight: 600;
      text-align: center;
    }

    &--mobile {
      text {
        font-size: 8px;
        text-align: center;
      }

      text[id^="Sarigato"] {
        font-size: 9.8px;
        font-weight: 600;
      }

      text[id^="_"] {
        font-size: 8px;
        font-weight: 600;
        text-align: center;
      }
    }

    @media only screen and (max-width: 71em /* 1136px */) {
      width: 100%;
      transform: translateX(0);
    }
  }

  &__stats-container.animate {
    .credibility__stats-image {
      @for $i from 1 through 24 {
        rect:nth-of-type(#{25 - $i}) {
          animation: reveal
            1s
            cubic-bezier(0.85, 0, 0.15, 1)
            #{$i *
            0.025}s
            both;
        }
      }
    }
  }
}

@keyframes reveal {
  from {
    clip-path: inset(100% 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
