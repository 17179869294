@import "styles/variables";
@import "styles/utils/visually-hidden";

.menu-button-container {
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 50em /* 800px */) {
    flex: 1 1 auto;
  }
}

.menu-button {
  font-weight: 600;
  font-size: max(12px, 1.2rem);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $grey-medium;

  display: flex;
  align-items: center;
  gap: 3rem;
  z-index: 2;
  position: relative;

  // line settings
  $thickness: 2px;
  $margin: 11%;
  $transition: 0.3s;

  &__text {
    transition: color 0.2s;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    &__text {
      @include visually-hidden();
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .menu-button__text {
        color: $white-almost;

        &--dark {
          color: $black-dark;
        }
      }

      .menu-button {
        &__icon {
          &::before {
            transform: translateY(100%);
          }
        }

        &__line {
          background-color: $white-almost;

          &--1,
          &--3 {
            transform: scaleY(0.85);
          }
        }
      }
    }
  }

  &__icon {
    width: 6rem;
    height: 10rem;
    background-color: $white-almost;
    box-shadow: none;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color $transition, box-shadow $transition;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -100%;
      left: 0;
      background-color: $black-dark;
      transition: transform $transition;
    }
  }

  &__line-container {
    display: block;
    width: 50%;
    height: auto;
    aspect-ratio: 1;

    position: relative;
    transition: transform $transition;
  }

  &__line {
    position: absolute;
    top: 0;

    display: block;
    background-color: $black-dark;

    &--1,
    &--2,
    &--3 {
      width: $thickness;
      height: 100%;
    }

    &--1 {
      left: $margin;
      transition: left $transition, opacity $transition, transform $transition,
        background-color $transition;
      transform: scaleY(0.7);
      transform-origin: top;
    }

    &--2 {
      left: calc(50% - $thickness / 2);
      transition: opacity $transition, background-color $transition;
      transform: scaleY(0.85);
      transform-origin: top;
    }

    &--3 {
      height: 100%;
      left: calc(100% - $thickness - $margin);
      transition: left $transition, background-color $transition,
        transform $transition;
      transform-origin: top;
    }

    &--4 {
      height: $thickness;
      width: 100%;

      top: $margin;
      left: 0;
      transition: top $transition, background-color $transition;
    }
  }

  &.expanded {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .menu-button__text {
          color: $black-dark;
        }

        .menu-button {
          &__line {
            &--3 {
              transform: scaleY(1);
            }
          }
        }
      }
    }

    .menu-button {
      &__icon {
        background-color: transparent;
        box-shadow: 0 0 0 1px $grey-medium-light;
      }

      &__line-container {
        transform: rotate(45deg) !important;
      }

      &__line {
        &--1,
        &--3 {
          left: calc(50% - $thickness / 2);
        }

        &--1,
        &--2 {
          opacity: 0;
        }

        &--4 {
          top: calc(50% - $thickness / 2);
        }
      }
    }
  }
}
