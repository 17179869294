@use "sass:math";
@import "styles/variables";

.clients {
  margin-bottom: 7.5rem;

  @media only screen and (max-width: 43em /* 688px */) {
    // margin-bottom: 4rem;
    margin-bottom: -44rem;
  }

  h3.clients__headline {
    margin-top: 4.9rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 9rem;

      .new-line {
        display: inline;
      }
    }
  }

  &__decoration {
    @media only screen and (max-width: 43em /* 688px */) {
      &.heading-decoration {
        transform: translateY(-143%);
      }
    }

    * > img {
      width: 125%;
    }
  }
}
