@import "styles/variables";

.client-list-container {
  max-width: 100%;
  overflow: hidden;

  &:first-of-type {
    margin-top: 3.4rem;
  }

  display: flex;

  @media only screen and (max-width: 43em /* 688px */) {
    overflow: visible;
    transform-origin: left center;

    @for $i from 0 through 5 {
      &:nth-of-type(#{$i + 1}) {
        transform: scale(0.6) translateY(-#{$i * 60%});
      }
    }
  }
}

.client-list {
  flex: 0 0;

  display: flex;
  align-items: center;
  justify-content: space-around;

  border-bottom: 2px solid $grey-light;
  height: 15rem;

  animation: translateHorizontally 24s linear infinite;

  @media only screen and (max-width: 43em /* 688px */) {
    height: 25rem;
  }
}
.client-list-container:nth-of-type(even) .client-list {
  animation-direction: reverse;
}

.client-item {
  flex: 0 1 auto;

  @media only screen and (max-width: 87em /* 1392px */) {
    padding: 0 4.8rem;
  }
}

@import "styles/animations";
