@import "styles/variables";

.team-gallery {
  max-width: 142.8rem;
  margin: 0 auto;
  padding: 0 $side-margin;

  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  align-items: end;
  column-gap: $gap;
  row-gap: clamp($gap, 4.5svw, 7.2rem);

  &__item {
    &--1 {
      grid-column: span 4;
    }
    &--2 {
      grid-column: span 6;
    }
    &--3 {
      grid-column: span 3;
    }
    &--4 {
      grid-column: span 4;
    }
    &--5 {
      grid-column: span 3;
    }
    &--6 {
      grid-column: span 5;
    }
    &--7 {
      grid-column: span 5;
    }
    &--8 {
      grid-column: span 4;
    }
    &--9 {
      grid-column: span 6;
    }
  }

  &__image {
    display: block;
    width: 100%;

    &--1 {
      transform: translateY(-11.4%);
    }

    &--3 {
      transform: translate(-12.56%, -7.49%);
    }

    &--5 {
      transform: translate(15.08%, 7.49%);
    }

    &--7 {
      transform: translate(7.42%, 7.49%);
    }

    &--8 {
      transform: translateX(-9.33%);
    }

    &--9 {
      transform: translateY(7.49%);
    }
  }

  &__video {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 560 / 315;
    transform: translateX(7.39%);
  }

  @media only screen and (max-width: 43em /* 688px */) {
    align-items: stretch;
    gap: $gap;
    padding: 0;

    &__image,
    &__video {
      transform: none;
    }

    &__image {
      height: 100%;
      object-fit: cover;

      &--1 {
        max-height: 32rem;
      }
      &--4 {
        max-height: 38rem;
      }
    }

    &__item {
      &--1 {
        order: 2;
        grid-column: span 10;
      }
      &--2 {
        order: 1;
        grid-column: span 10;
      }
      &--3 {
        order: 3;
        grid-column: span 5;
      }
      &--4 {
        order: 5;
        grid-column: span 10;
      }
      &--5 {
        order: 4;
        grid-column: span 5;
      }
      &--6 {
        order: 6;
        grid-column: span 5;
      }
      &--7 {
        order: 8;
        grid-column: span 10;
      }
      &--8 {
        order: 7;
        grid-column: span 5;
      }
      &--9 {
        order: 9;
        grid-column: span 10;
      }
    }
  }
}
