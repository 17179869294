@use "sass:color";
@import "styles/variables";

.navigation-panel {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  background-color: color.adjust($white-almost, $alpha: -0.1);
  backdrop-filter: blur(15px);

  width: calc((100% - 142.8rem) / 2 + 28.56rem);
  min-width: 50rem;
  height: 100dvh;
  padding-top: 2.6rem;
  padding-left: 7rem;
  padding-bottom: 6rem;

  clip-path: inset(2.6rem 3rem calc(100% - 12.6rem) calc(100% - 9rem));
  transition: clip-path 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-y: scroll;
  overscroll-behavior-y: contain;

  // hide scrollbar
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  @media only screen and (max-width: 107.5em /* 1720px */) {
    min-width: 54rem;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    min-width: 100dvw;
    padding: 4.8rem;
  }

  > * {
    opacity: 0;
    transform: translateX(10%);
    transition: opacity 0.2s, transform 0.2s;

    @media only screen and (max-width: 43em /* 688px */) {
      transition: none;
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.expanded {
    clip-path: inset(0 0 0 0);

    > * {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 0.6s, transform 0.6s;

      @media only screen and (max-width: 43em /* 688px */) {
        transition: none;
      }
    }
  }

  &__copyright {
    font-size: max(12px, 1.4rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    @media only screen and (max-height: 53.75em /* 860px */) and (min-width: 107.5em /* 1720px */) {
      display: none;
    }

    @media only screen and (max-height: 53.75em /* 860px */) and (max-width: 107.5em /* 1720px */) {
      display: flex;
    }

    @media only screen and (max-height: 45em /* 720px */) and (max-width: 107.5em /* 1720px */) {
      display: none;
    }
  }

  &__text {
    flex: 1 0;
  }

  &__logo {
    flex: 0 1 auto;
    width: 16.8rem;
    height: auto;
    margin-right: 6rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-right: 0;
    }

    & * {
      fill: $grey-dark;
    }
  }
}
