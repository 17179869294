@mixin visually-hidden() {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;

  // fix for positioned table caption that could become anonymous cells
  &:not(caption) {
    position: absolute !important;
  }
}

.visually-hidden {
  @include visually-hidden();
}
