@use "sass:math";
@import "styles/mixins";
@import "styles/variables";
@import "styles/utils/visually-hidden";

.scroll-down {
  @include rectangle();
  box-shadow: inset 0 0 0 1px $grey-medium;
  position: relative;
  overflow: hidden;
  transition-property: transform;
  transition-duration: 0.3s;

  @media only screen and (max-width: 43em /* 688px */) {
    &__text {
      align-self: flex-end;
    }
  }

  @media only screen and (max-width: 43em /* 688px */) and (min-aspect-ratio: 386 / 691) {
    &__text {
      display: none;
    }
  }

  @media only screen and (max-width: 43em /* 688px */) and (min-aspect-ratio: 386 / 617) {
    background-color: $white-almost;
  }

  &.to-bottom {
    transform: scaleY(110%);
    transform-origin: top;

    .scroll-down__icon-container--default {
      transform: translateY(100%);

      .scroll-down__icon {
        transform: scaleX(0);
      }
    }
  }

  &.to-bottom.reset {
    transform: scaleY(100%);
    transform-origin: top;
  }

  &.to-top {
    transform: scaleY(100%);
    transform-origin: top;

    .scroll-down__icon-container--default {
      transform: translateY(-100%);
      transition: none;

      .scroll-down__icon {
        transform: scaleX(0);
      }
    }
  }

  &.to-middle {
    transform: scaleY(90%);
    transform-origin: bottom;

    .scroll-down__icon-container--default {
      transform: translateY(0);

      .scroll-down__icon {
        transform: scaleX(1);
      }
    }
  }

  &.to-middle.reset {
    transform: scaleY(100%);
    transform-origin: bottom;
  }

  &__icon-container {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform 0.5s;

    &--hover {
      top: -100%;
      background-color: $black-dark;
      opacity: 0;
      transition: transform 0.5s, opacity 0s 0.6s;

      .scroll-down__icon {
        transform: scaleX(0);

        & * {
          fill: $white-almost;
        }
      }
    }
  }

  &__icon {
    width: math.div(2.041, 6) * 100%;
    height: math.div(3.471, 10) * 100%;

    transition: transform 0.3s ease-in-out;

    & * {
      fill: $black-dark;
    }
  }
}

.scroll-down-link {
  display: flex;
  align-items: center;
  gap: min(4svw, 5.9rem);
  transform: rotate(0.01deg);
  transition: color 0.3s;

  @media (hover: hover) and (pointer: fine) {
    &:is(:hover, :active) {
      color: $black-dark;

      .scroll-down__icon-container {
        transform: translateY(100%);

        &--default .scroll-down__icon {
          transform: scaleX(0);
        }

        &--hover {
          transition: transform 0.5s, opacity 0s 0s;
          opacity: 1;
          .scroll-down__icon {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 43em /* 688px */) {
    gap: 3rem;
  }
}
