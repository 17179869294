$sans: "Syne", sans-serif;
$serif: "ivymode", serif;

$black-dark: #2f2e2f;
$grey-dark: #4d4d4d;
$grey-medium: #b9b1b4;
$grey-medium-light: #d4cecf;
$grey-light: #dfd9dc;
$white-dark: #f2f0f1;
$white-almost: #f9f6f8;
$red: #ea2046;
$red-pink: #e61c62;
$violet: #3b243b;

$gap: 1.6rem;
$side-margin: 3.2rem;
