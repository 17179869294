@use "sass:math";
@import "styles/variables";

.contact-person {
  flex: 0 1 50%;

  @media (hover: hover) and (pointer: fine) {
    flex: 0 1 43.66%;

    &:hover {
      flex: 0 1 calc(50% - $gap / 2);
    }
  }

  padding: 3.7rem;
  position: relative;
  color: #000;

  height: 58rem;
  backdrop-filter: blur(25px);
  overflow-y: clip;
  transition: flex 0.4s;

  @media only screen and (max-width: 51.25em /* 820px */) {
    height: 53rem;
  }

  @media only screen and (max-width: 44.375em /* 710px */) {
    height: 50rem;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    overflow: hidden;
    flex: 0 1 50rem;
    height: auto;
    width: calc(100% - 4.8rem);
    padding: 4.2rem 5.2rem;
    padding-left: 9.6rem;
  }

  @media only screen and (max-width: 21.5em /* 344px */) {
    padding-left: $side-margin;
  }

  @media only screen and (max-width: 21.5em /* 344px */) {
    width: 100%;
    flex: 0 0 40rem;
  }

  &:last-of-type {
    margin-top: 8rem;

    div.contact-person__texture {
      background-position: right;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      margin-top: 0;
      align-self: flex-end;
      padding: 4.2rem 5.2rem;
      padding-left: 4.8rem;
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      padding-left: $side-margin;
    }
  }

  &__role {
    font-size: max(14px, 1.6rem);
    font-family: $serif;
    font-style: italic;
    letter-spacing: 0.02em;
    line-height: math.div(28, 16);

    margin-bottom: 2.4rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin-bottom: 1.6rem;
    }
  }

  &__name {
    font-size: 3.7rem;
    font-weight: 600;

    margin-bottom: 1.6rem;

    @media only screen and (max-width: 43em /* 688px */) {
      font-size: 3rem;
    }
  }

  &__name-part {
    display: block;

    @media only screen and (max-width: 43em /* 688px */) {
      display: inline;
    }
  }

  &__email {
    font-size: max(14px, 1.6rem);
    line-height: math.div(28, 16);
  }

  &__image {
    position: absolute;
    pointer-events: none;
    top: 13.7rem;
    left: 22.8rem;

    width: 54.92%;
    height: auto;

    &.ola {
      top: 15.2rem;
      left: 19.2rem;
      width: 62.87%;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      width: 52.83%;
      top: 38%;
      left: 28%;

      &.ola {
        top: 38%;
        left: 28%;
        width: 62.87%;
      }
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      left: 38%;
      height: 70%;
      top: 50%;
      width: auto;

      &.ola {
        top: 50%;
        width: auto;
        height: 70%;
      }
    }
  }

  div.contact-person__texture {
    opacity: 0.6;
  }

  &__email-link {
    display: inline-flex;
    align-items: center;
    gap: 1.25rem;

    &.underline-hover:is(:link, :visited)::after {
      background-color: $grey-medium;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      gap: 2rem;
      display: flex;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .contact-person {
          &__link-text {
            transform: translateX(0);
          }

          &__arrow {
            transform: scaleY(1);
            opacity: 1;
          }
        }
      }
    }
  }

  &__link-text {
    display: block;
    transition: transform 0.2s;
    transform: translateX(0);

    @media (hover: hover) and (pointer: fine) {
      transform: translateX(-3.9rem);
    }
  }

  &__arrow {
    width: 2rem;
    min-width: 2rem;
    transition: transform 0.2s, opacity 0.2s;
    transform: scaleY(1);
    opacity: 1;

    @media only screen and (max-width: 43em /* 688px */) {
      width: 2.1rem;
    }

    @media (hover: hover) and (pointer: fine) {
      transform: scaleY(0);
      opacity: 0;
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      display: none;
    }
  }
}
