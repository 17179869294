@use "sass:color";
@import-normalize; // bring in normalize.css styles
@import "styles/reset"; // custom reset styles
@import "styles/variables";
@import "styles/utils";

html {
  font-size: 62.5%; // 10px
  scroll-behavior: smooth;

  @media only screen and (max-width: 107.5em /* 1720px */) {
    font-size: 56.25%; // 9px;
  }

  @media only screen and (max-width: 56.25em /* 900px */) {
    font-size: 50%; // 8px;
  }

  @media only screen and (max-width: 37.5em /* 600px */) {
    font-size: 43.75%; // 7px;
  }
}

body {
  font-family: $sans;
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1;

  color: $black-dark;
  background-color: $white-dark;

  box-sizing: border-box;
}

#root {
  overflow-x: clip;
}

*:focus-visible {
  outline: 1rem solid rgb(0 200 0 / 0.5);
}

@mixin selection-styles {
  background-color: color.adjust($red, $alpha: -0.8);
}

// keep below selection selectors separated - they do not work together

::-moz-selection {
  @include selection-styles();
}

::selection {
  @include selection-styles();
}

// smooth scroll
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
