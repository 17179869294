@import "styles/variables";

.foundation-gallery {
  margin-top: 6rem;
  margin-bottom: 7.5rem;
  overflow: hidden;
  justify-content: center;
  display: flex;
  gap: 2rem;
  position: relative;
  z-index: 3;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-top: 4.8rem;
    margin-bottom: 8.2rem;

    .foundation-item:nth-of-type(2) .foundation-gallery {
      margin-bottom: 0;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  &__item {
    height: 30rem;
    background-color: $grey-light;

    @media only screen and (max-width: 43em /* 688px */) {
      height: 26rem;
    }
  }

  &__image {
    object-fit: cover;
    height: 100%;
  }

  &--overflown {
    justify-content: flex-start;

    .foundation-gallery {
      &__container.kp {
        animation: translateHorizontallyWithGap 64s linear infinite;
      }
    }

    .foundation-gallery {
      &__container.ha {
        animation: translateHorizontallyWithGap 64s linear infinite;
      }
    }
  }
}

@media only screen and (max-width: 43em /* 688px */) {
  .foundation-item:last-of-type .foundation-gallery {
    margin-bottom: 6.2rem;
  }
}

@keyframes translateHorizontallyWithGap {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 2rem));
  }
}
