.language-nav {
  font-size: max(16px, 1.6rem);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;

  @media only screen and (max-width: 43em /* 688px */) {
    margin-top: 8rem;
  }

  &__list {
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5.3rem;

    @media only screen and (max-width: 43em /* 688px */) {
      gap: 9.6rem;
    }
  }

  &__item {
    display: flex;
  }
}
