@use "sass:math";
@import "styles/variables";

.offices {
  font-size: max(14px, 1.6rem);
  line-height: 2.4rem;
  padding-top: 1.3rem;

  @media only screen and (max-width: 30em /* 480px */) {
    max-width: 38rem;
  }

  &__list {
    display: grid;
    grid-template-columns: minmax(max-content, min(9svw, 12.2rem)) minmax(
        max-content,
        1fr
      );
    column-gap: $gap;
    row-gap: 2.8rem;

    @media only screen and (max-width: 30em /* 480px */) {
      grid-template-columns: minmax(max-content, 12rem) minmax(max-content, 1fr);
    }
  }

  &__contact {
    grid-column: 2;
  }
}
