@use "sass:math";
@import "styles/variables";

.office {
  display: contents;

  &__city {
    font-family: $serif;
    font-style: italic;
    letter-spacing: 0.05em;
  }
}
