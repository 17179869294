@use "sass:math";
@import "styles/variables";

.slide {
  $margin: 3rem;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);

  transition: transform 0.5s ease-out;

  padding: 6.25rem 4.3rem 0 4.3rem;

  @media only screen and (max-width: 49em /* 784px */) {
    z-index: 2;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    padding: 4rem 1.8rem 0 1.8rem;
  }

  @media only screen and (max-width: 23.4375em /* 375px */) {
    padding: 3rem 0.5rem 0 0.5rem;
  }

  div.slide__background {
    opacity: 0.4;
  }

  &__blockquote {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__quote-icon {
    width: 6.5rem;
    height: auto;

    margin: 0 3.65rem;
    margin-bottom: 3.02rem;

    @media only screen and (max-width: 43em /* 688px */) {
      margin: 0 auto;
      margin-bottom: 1.6rem;
    }

    @media only screen and (max-width: 23.4375em /* 375px */) {
      margin-bottom: 0.5rem;
    }
  }

  &__content {
    flex: 1 1 auto;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: math.div(37, 21);
    color: #000;

    display: flex;
    flex-direction: column;
  }

  &__paragraphs-container {
    flex: 1 1 auto;
    position: relative;
    z-index: 1;
  }

  &__paragraphs-expand {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 2rem $margin 0 $margin;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    transition-property: background-color, box-shadow;
    transition-duration: 0.2s;

    &--overflown {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(transparent 70%, #eeeced),
          radial-gradient(
            ellipse 50% 50% at bottom right,
            #eeeced 60%,
            transparent
          );
        mask: linear-gradient(
          to right,
          transparent,
          black 4%,
          black 96%,
          transparent
        );
        pointer-events: none;
        z-index: 1;

        transition: opacity 0.2s ease-out;
      }
    }

    &--expanded {
      height: auto;
      background-color: $white-almost;
      box-shadow: 0 0 2rem 1px rgb(0 0 0 / 0.075);
      padding-bottom: 8rem;

      &::after {
        opacity: 0;
      }
    }
  }

  &__more-button {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: $margin;

    font-size: max(12px, 1.6rem);
    line-height: 1;
    padding: 1rem;
    background-color: #eeeced;
    border: 1px solid $grey-light;
    border-bottom: none;
    color: $grey-dark;

    &--expanded {
      border-bottom: 1px solid $grey-light;
      background-color: transparent;
      transform: translateY(-2.5rem);
    }

    &:disabled {
      display: none;
    }
  }

  &__footer {
    flex: 0 0 18rem;
    display: flex;
    align-items: center;
    border-top: 1px solid $grey-light;

    margin: 0 $margin;

    @media only screen and (max-width: 43em /* 688px */) {
      flex-direction: column;
      justify-content: space-around;
      padding: 2.4rem 0;
      flex: 0 0 21rem;
    }

    @media only screen and (max-width: 23.4375em /* 375px */) {
      flex: 0 0 18rem;
    }
  }

  &__footer-item {
    flex: 0 0 50%;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 43em /* 688px */) {
      flex: 0 1;
    }
  }

  &__footer-item:first-of-type {
    border-right: 1px solid $grey-light;

    @media only screen and (max-width: 43em /* 688px */) {
      border: none;
    }
  }

  &__author {
    font-weight: 600;
    font-size: max(18px, 2.1rem);
    line-height: math.div(28, 21);
    color: #000;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__role {
    font-size: max(14px, 1.6rem);
    line-height: math.div(28, 16);
    color: #000;
    text-transform: lowercase;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    opacity: 0.6;
  }
}
