@use "sass:color";
@import "styles/variables";

.section-heading {
  font-family: $serif;
  font-size: max(16px, 2.1rem);
  font-style: italic;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  transition: transform 0.5s;

  &:focus-visible {
    transform: scale(1.2);
    outline: none;
  }

  @media only screen and (max-width: 43em /* 688px */) {
    gap: 2.4rem;
  }

  &__icon {
    display: block;
    width: max(16px, 1.8rem);
    height: auto;
    aspect-ratio: 1;
    background: linear-gradient(
      to bottom,
      $red,
      color.adjust($red-pink, $alpha: -0.5)
    );
    border-radius: 99px;
  }
}
