@use "sass:math";
@import "styles/variables";

.hero {
  grid-column: content-start / content-end;
  grid-row: 1 / 2;
  z-index: 1;

  height: 100%;
  padding: 0 $side-margin;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  font-family: $sans;

  @media only screen and (max-width: 43em /* 688px */) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;

    justify-content: flex-end;
    padding: 0;
  }

  &__top {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__figure {
    position: absolute;
    height: 10rem;
    top: 2.6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media only screen and (max-width: 43em /* 688px */) {
      &.figure-determination {
        display: none;
      }
    }
  }

  &__text-box {
    max-width: 53rem;
    flex: 0 1 35%;

    display: flex;
    gap: 4.5rem;
    flex-direction: column;
    justify-content: center;
  }

  &__heading {
    font-family: $serif;
    font-size: 5rem;
    font-weight: 500;
    font-style: italic;
    line-height: math.div(56, 50) * 1;

    @media only screen and (max-width: 43em /* 688px */) {
      color: transparent;
    }
  }

  &__paragraph {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: math.div(37, 28) * 1;

    @media only screen and (max-width: 43em /* 688px */) {
      color: transparent;
    }
  }

  &__scroll-box {
    flex: 0 1 35%;

    font-size: max(12px, 1.2rem);
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $grey-medium;

    display: flex;
    align-items: center;

    @media only screen and (max-width: 43em /* 688px */) {
      flex: 0 1 50%;
      padding-left: 9svw;
      padding-bottom: 9svw;
      align-items: flex-end;

      position: sticky;
      bottom: 0;
    }
  }
}

.hero-mobile {
  padding-top: max(8rem, 16svw);

  &__heading {
    font-family: $serif;
    font-style: italic;
    font-size: 4rem;
    line-height: math.div(37, 28);
    text-align: center;
    margin-bottom: 1.8rem;
  }

  &__paragraph {
    font-size: 2.29rem;
    font-weight: 500;
    line-height: math.div(21, 16);
    text-align: center;
    padding: 0 $side-margin;
    max-width: 50rem;
    margin: 0 auto;
    margin-bottom: max(6rem, 16svw);
  }
}
