@use "sass:math";
@import "styles/variables";
@import "styles/mixins";

.foundation-item {
  &__container {
    max-width: 142.8rem;
    margin: 0 auto;
    padding: 0 $side-margin;
    position: relative;

    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 70.875em /* 1134px */) {
      padding-left: 0;
    }

    @media only screen and (max-width: 49em /* 784px */) {
      padding-right: 0;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      flex-direction: column;
    }
  }

  &__header {
    flex: 1 1 min-content;
    min-width: 32rem;
    margin-top: 8rem;

    display: flex;
    position: relative;
    padding: 8rem 3.2rem;
    gap: 3.2rem;

    z-index: 2;

    @media only screen and (max-width: 70.875em /* 1134px */) {
      display: block;
      padding: 3.2rem;
      transform: translateX($side-margin);
    }

    @media only screen and (max-width: 49em /* 784px */) {
      transform: translateX(0) scale(1.1);
      transform-origin: left;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      transform: none;
      padding: 4.8rem;
      padding-left: 9.6rem;
      margin-right: 4.8rem;
      margin-bottom: -4.8rem;
      gap: 1.6rem;
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      margin-right: 0;
      padding-left: $side-margin;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 6rem);
      height: 100%;
      backdrop-filter: blur(20px);
      z-index: -1;

      @media only screen and (max-width: 70.875em /* 1134px */) {
        width: 100%;
      }
    }
  }

  &__ink-container {
    position: absolute;
    z-index: -2;
    top: 0;
    pointer-events: none;

    &--1-mobile,
    &--2-mobile {
      display: none;
    }

    &--1 {
      width: getRelativeDimension(762, 690);
      right: getRelativeDimension(73, 690);
      transform: translateY(getRelativeDimension(-160, 762));
    }

    &--2 {
      width: getRelativeDimension(697, 690);
      right: getRelativeDimension(98, 690);
      transform: translateY(getRelativeDimension(-191, 778));
    }

    @media only screen and (max-width: 43em /* 688px */) {
      &--1,
      &--2 {
        display: none;
      }

      &--1-mobile,
      &--2-mobile {
        display: block;
        width: 200%;
        left: 50%;
        top: -16%;
        transform: translate(-58%, 0) rotate(-10deg);
        z-index: 1;
      }

      &--2-mobile {
        width: 170%;
        top: -22%;
        transform: translate(-45%, 0) rotate(-180deg);
      }
    }
  }

  &__ink {
    width: 100%;
  }

  &__origami-container {
    position: absolute;
    width: 6rem;
    aspect-ratio: 1;
    bottom: 0;
    left: calc(50% + 3rem);
    transform: translate(-50%, getRelativeDimension(155, 55));

    @media only screen and (max-width: 70.875em /* 1134px */) {
      left: 50%;
      transform: translate(-50%, getRelativeDimension(100, 55));
    }

    @media only screen and (max-width: 43em /* 688px */) {
      top: 0;
      transform: translate(calc(-50% + 2.4rem), -150%);
      width: 5.5rem;
    }

    @media only screen and (max-width: 21.5em /* 344px */) {
      transform: translate(calc(-50%), -150%);
    }
  }

  &__origami {
    width: 100%;
  }

  &__texture.texture {
    width: calc(100% + 6rem);
    opacity: 0.6;

    @media only screen and (max-width: 70.875em /* 1134px */) {
      width: 100%;
    }
  }

  &__header-text {
    flex: 0 1 38.1rem;
  }

  &__logo-container {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 70.875em /* 1134px */) {
      justify-content: flex-start;
      margin-bottom: 3.2rem;
    }

    @media only screen and (max-width: 43em /* 688px */) {
      max-width: 15rem;
    }
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 600;
    color: #000;

    margin-bottom: 2.1rem;
  }

  &__description {
    font-size: max(14px, 1.6rem);
    line-height: math.div(24, 16);
  }
}
