.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 0 3rem;
  padding-top: 2.6rem;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__outside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 0;
  }
}
