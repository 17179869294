@import "styles/variables";

.figure-determination {
  display: flex;
  gap: 1em;
  align-items: center;

  font-family: $serif;
  font-style: italic;
  font-size: 2.1rem;
  letter-spacing: 0.05em;

  &__image {
    height: 0.9em;
    width: auto;
  }
}
