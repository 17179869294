@use "sass:math";
@import "styles/variables";

.credibility-badges {
  display: flex;
  justify-content: center;

  &__badge-container {
    aspect-ratio: 1;
    width: 13.8rem;
    display: grid;
    place-items: center;
    position: relative;

    @media only screen and (max-width: 43em /* 688px */) {
      width: 13rem;
    }

    &:first-of-type {
      transform: translateX(math.div(7, 138) * 100%);
    }

    &:last-of-type {
      transform: translateX(math.div(7, 138) * -100%);
    }
  }

  &__circle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__badge-container:last-of-type {
    .credibility-badges__circle-container {
      transform: rotate(180deg);
    }
  }

  &__circle {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }

  &__badge-image {
    max-width: 70%;
    max-height: 70%;
    opacity: 0;
  }

  &.animate {
    .credibility-badges {
      &__circle {
        animation: stroke 2s ease-out forwards;
      }

      &__badge-image {
        animation: fadeIn 0.75s linear both;
      }
    }
  }
}

@keyframes stroke {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
